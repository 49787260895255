/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  color: white;
  background: #000;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

button,
input,
select {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  cursor: pointer;
}

button {
  background: #369;
  color: #fff;
  padding: 5px 0;
  border: none;
}

ul {
  background: #000;
  color: #eee;
}

h2 {
  font-size: 16px;
}

#red,
#green,
#blue {
  min-width: 10px;
}

#red {
  background-color: red;
}

#green {
  background-color: green;
}

#blue {
  background-color: blue;
}

#yellow {
  background-color: yellow;
}
